/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
// import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

// import { rhythm } from "../utils/typography";

function Bio({ img, author, authorDescription, social }) {
  return (
    <blockquote
      style={{
        margin: "3rem 0rem"
      }}
    >
      <div
        style={{
          display: `flex`,
          marginBottom: "0.5rem"
        }}
      >
        <Image
          fixed={img}
          alt={author}
          style={{
            marginRight: "1rem",
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`
          }}
          imgStyle={{
            borderRadius: `50%`
          }}
        />
        <div>
          <p>
            <small> Written by</small>
          </p>
          <p>
            <strong>{author}</strong>
          </p>
        </div>
      </div>
      <div>
        <p>{authorDescription}</p>
        <ul>
          {social.map((icon, index) => {
            return (
              <li key={index}>
                <a href={icon}>
                  <small> {icon}</small>
                </a>
              </li>
            );
          })}
        </ul>
        {/* <a href={social[0]}>
          <small>{social[0]}, </small>
        </a>
        <a href={social[1]}>
          <small>{social[1]}</small>
        </a> */}
      </div>
    </blockquote>
  );
}

export default Bio;

// query BioQuery {
//   avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
//     childImageSharp {
//       fixed(width: 50, height: 50) {
//           ...GatsbyImageSharpFixed
//       }
//     }
//   }
//   site {
//     siteMetadata {
//       author
//       social {
//         twitter
//       }
//     }
//   }
// }
// /* <a href={`https://twitter.com/${social.twitter}`}> */
