import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
// import Bio from "../components/bio";
import Layout from "../layout";
import SEO from "../seo";
import Img from "gatsby-image";
import Bio from "./Bio";

// import { rhythm, scale } from "../utils/typography";

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulPostCards;
    // const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={post.title}>
        <SEO
          title={post.title}
          description={post.metadata}
          keywords={[
            `smarta kontrakt sverige svenskt företag`,
            ` svenskt företag blockchain utveckling sverige`,
            `Blockchain development smarta kontrakt och blockchain utveckling`,
            `Smart Contract development `,
            `Ethereum Development sverige eterumutveckling `,
            `Solidity Development svenskt företag `,
            `Hyperledger Development Sweden`,
            `ICO STO Development Sweden Stockholm Malmo`,
            `Smart contract Audit Sweden smart kontraktskontroll`,
            `Blockchain Consulting and Development Sweden Stockholm Malmo`,
            `Full-Stack Development Sweden Stockholm Malmo`,
            `svensk blockchain utveckling sverige`,
            `svensk blockchain smart kontraktsutveckling`,
            `Full-Stack Development services sverige`,
            `blockchain company Sweden`,
            `ERC20 Ethereum Token Development Sweden`,
            `Swedish Ethereum Development ICO`,
            `gatsby smarta kontrakt och blockchain utveckling sverige`,
            `react sverige svenskt företag `,
            `Nodejs sverige svenskt företag `,
            `JAMStack Swedish Sweden Stockholm Malmo`,
            `Gatsby Website development Sweden`,
            `React Redux Graphql Front developer svenskt företag `,
            `React Redux Graphql Backend development Sweden Stockholm Malmo`,
            `Cryptocurency development Sweden`,
            `Wallet development Cryptocurency Smart Contract`,
            `Website development Sweden`,
            `Website blog hemsida utveckling Sweden Stockholm Malmo`,
            `Website blog hemsida utveckling svenskt företag`
          ]}
        />

        <PostDWrapper>
          <div className="image">
            <Img fluid={post.heroImage.fluid} />
          </div>
          <h1>{post.title}</h1>
          <p
            style={{
              marginTop: "0.1rem",
              marginBottom: "3rem",
              textAlign: "center"
            }}
          >
            {"Published: " + post.publishDate}
          </p>
          {/* Here we are running the embaded html */}
          <div
            dangerouslySetInnerHTML={{
              __html: post.postContent.childContentfulRichText.html
            }}
          />
          <div
            style={{
              marginBottom: "3rem"
            }}
          />
          <Bio
            img={post.bioImage.fixed}
            author={post.author}
            authorDescription={post.authorDescription}
            social={post.social}
          />
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0
            }}
          >
            <li>
              {previous && (
                <Link to={previous.slug} rel="prev">
                  ← {previous.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.slug} rel="next">
                  {next.title} →
                </Link>
              )}
            </li>
          </ul>
        </PostDWrapper>
      </Layout>
    );
  }
}

/**************************************/
//       ServicesBodyWrapper SC
/**************************************/
const PostDWrapper = styled.div`
  margin: 4rem 6rem;
  padding: 3rem 6rem;
  h1 {
    text-align: center;
    font-size: 3.6rem;
  }

  .image {
    /* max-height: 80%; */
    /* padding: 0rem 4rem; */
  }
  @media (max-width: 967px) {
    margin: 4rem 1.5rem;
    padding: 3rem 1.5rem;
    h1 {
      font-size: 3rem;
    }
    /* .image {      
      padding: 0rem;
    } */
  }

  /* justify-content: center; */
`;

export const pageQuery = graphql`
  query postCardsBySlug($slug: String!) {
    contentfulPostCards(slug: { eq: $slug }) {
      index
      heroImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      title
      subTitle
      metadata
      bioImage {
        fixed(width: 50, height: 50) {
          ...GatsbyContentfulFixed
        }
      }
      publishDate(formatString: "DD MMMM, YYYY")
      postContent {
        childContentfulRichText {
          html
        }
      }
      author
      authorDescription
      social
    }
  }
`;

export default PostTemplate;
